import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled.div`
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 90vh;
`

const FullTextContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: auto auto 0 auto;
  width: 80%;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 24px 36px;
      font-size: 24px;
      font-weight: 300;
      width: 86%;
    }
  }

  a {
    color: black;
  }

  @media screen and (max-width: 900px) {
    min-height: 0;
    width: 100%;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 6% auto;

  :first-child {
    margin: 6% auto 2% auto;
  }

  :last-child {
    margin: 2% auto 6% auto;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

const MediumTextContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: auto;
  width: 60%;
  min-height: 0;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px 36px 36px;
      font-size: 24px;
      font-weight: 300;
      width: 86%;
    }
  }

  a {
    color: black;
  }

  @media screen and (max-width: 900px) {
    margin: 24px 0;
    width: 100%;
    min-height: none;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

const SmallTextContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  color: black;
  margin: auto 42px auto 42px;
  width: 40%;
  min-height: 260px;

  h2 {
    color: black;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 36px;
    padding: 24px 36px;
  }

  p {
    font-family: "Roboto", sans-serif;

    :nth-child(2) {
      padding: 0 36px 36px 36px;
      font-size: 24px;
      font-weight: 300;
      width: 86%;
    }
  }

  a {
    color: black;
  }

  @media screen and (max-width: 900px) {
    margin: 24px 0;
    width: 100%;
    min-height: 0;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
      :nth-child(2) {
        font-size: 18px;
        width: 80%;
      }
      :nth-child(3) {
        font-size: 18px;
      }
    }
  }
`

class FAQItems extends Component {
  render() {
    return (
      <Container>
        <Row>
          <SmallTextContainer id="get-started">
            <h2>How do I get started?</h2>
            <p>
              Sign up <Link to="/">here</Link> for early access to be a part of
              our beta test group. We will be in touch with details when the
              beta product launches.
            </p>
          </SmallTextContainer>
          <MediumTextContainer id="how-to-buy">
            <h2>Can I buy Clink tokens?</h2>
            <p>
              Not at this time, however this is an option we hope to make
              available in the future. For now, the only ways to obtain Clink
              tokens are to earn Clink token rewards or to sign up for early
              access to the beta program. Beta program participants will receive
              free tokens to help get them started.
            </p>
          </MediumTextContainer>
        </Row>
        <Row>
          <FullTextContainer id="how-to">
            <h2>How do I clink?</h2>
            <p>
              To clink with another user, you both must be within one grid
              square each other. Both users should open their Clink app on their
              mobile device and select the clink icon. The Clink app will
              generate a QR code and activate a QR scanner. One user should scan
              the other user’s QR code. Once the scan is confirmed, the clink is
              complete!
            </p>
          </FullTextContainer>
        </Row>
        <Row>
          <SmallTextContainer id="city-availability">
            <h2>When will clink launch in my city?</h2>
            <p>
              <Link to="/">Sign up for updates</Link> and keep an eye out
              for our user survey. The user survey will help us decide which
              geographies to launch in first!
            </p>
          </SmallTextContainer>
          <MediumTextContainer id="reward-calculation">
            <h2>How are Clink token rewards calculated?</h2>
            <p>
              We are still tweaking the exact formula for calculating the
              rewards. Generally speaking, if you stake tokens on a grid square
              you will earn more tokens if there is more user activity at that
              grid square. When you clink with someone, you will earn more
              tokens if you clink on a grid square with more tokens staked, and
              you will earn more tokens if the person you clink with has more
              tokens staked on them.
            </p>
          </MediumTextContainer>
        </Row>
        <Row>
          <FullTextContainer id="how-to-earn">
            <h2>How do I earn Clink tokens?</h2>
            <p>
              There are three ways to earn Clink tokens: <br />
              <br /> 1) Stake tokens on a grid square. When people check-in on
              the grid square, or clink with each other on the grid square, you
              will earn reward tokens. <br />
              <br /> 2) Check-in or clink at a grid square with tokens staked on
              it. <br />
              <br /> 3) Stake tokens on another user, earn tokens when they
              clink with other users.
            </p>
          </FullTextContainer>
        </Row>
        <Row>
          <SmallTextContainer id="redeem">
            <h2>Can I redeem Clink tokens for cash?</h2>
            <p>Not at this time.</p>
          </SmallTextContainer>
          <MediumTextContainer>
            <h2>Who is building Clink?</h2>
            <p>
              Learn more about the team on our{" "}
              <Link to="/about#team">about page</Link>.
            </p>
          </MediumTextContainer>
        </Row>
        <Row>
          <FullTextContainer id="token-use">
            <h2>What are Clink tokens useful for?</h2>
            <p>
              Think of Clink tokens as a rallying cry that people should show up
              to the place where you stake tokens, or as a curation device to
              tell people that some location is a great place to connect or that
              someone is a good person to meet. There are always good reasons to
              get people together, whether you are throwing a block party,
              giving a musical performance, or you want to attract customers to
              your business or your favorite bar.
            </p>
          </FullTextContainer>
        </Row>
        <Row>
          <MediumTextContainer id="do-tokens-expire">
            <h2>Do Clink tokens expire?</h2>
            <p>
              Yes, Clink tokens expire each season (approx. 90 days), however
              users will receive a reward of tokens at the beginning of the next
              season based on their activity in the prior season.
            </p>
          </MediumTextContainer>
          <SmallTextContainer id="investment">
            <h2>Are Clink tokens an investment product?</h2>
            <p>
              No! Clink tokens are not designed as an investment product.
              Because the tokens expire each season, if you attempt to obtain
              Clink tokens and hold them as an investment you are likely to lose
              the entire token value.
            </p>
          </SmallTextContainer>
        </Row>
        <Row>
          <FullTextContainer id="build-on-the-platform">
            <h2>
              I have something I want to build on the Clink platform, how to I
              get started?
            </h2>
            <p>
              We want to hear about your project! Clink’s developer tools are
              not available yet, but drop us a line here and we can explore how
              we can work together.
            </p>
          </FullTextContainer>
        </Row>
      </Container>
    )
  }
}

export default FAQItems
